<template>
  <div class="error-page">
    <h1 v-if="error?.statusCode">{{ error.statusCode }}</h1>
  </div>
</template>

<script setup>
defineProps(['error'])

useHead(() => {
  return {
    title: "Stress-Free Immigration Solutions for UK Businesses",
    description: "Guiding you through Sponsor Licence, Skilled Worker visas, and Sponsorship Compliance to make international hiring simple."
  }
})
</script>

<style scoped>
.error-page {
  text-align: center;
  padding: 2rem;
}
</style>